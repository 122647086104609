define('gooru-web/utils/gainsight-initialization',['exports','gooru-web/utils/hash'],function(exports,_gooruWebUtilsHash){exports.setGainsightIdentity = setGainsightIdentity;exports.resetGainSight = resetGainSight;var isInitialized=false;var scriptSrc='/config/gainsight-script.js'; /**
 * Loads the Gainsight script dynamically into the DOM.
 *
 * @returns {Promise<void>} - A promise that resolves when the script is loaded successfully, and rejects if an error occurs.
 */var loadGainsightScript=function loadGainsightScript(){return new Promise(function(resolve,reject){var script=document.createElement('script');script.src = scriptSrc;script.async = true;script.onload = function(){resolve();};script.onerror = function(){reject(new Error('Gainsight script failed to load'));};document.body.appendChild(script);});}; /**
 * Sets up a Gainsight identity based on the provided session data.
 *
 * This function initializes the Gainsight identity only once for a signed-in user
 * and ensures that all required fields (tenantName, userEmail, userRole) are available.
 *
 * @param {Object} session - The session object containing user details.
 * @param {Object} session.authenticated - Authenticated session data.
 * @param {Object} session.authenticated.tenant - Tenant details.
 * @param {string} session.authenticated.tenant.tenantName - Name of the tenant.
 * @param {Object} session.authenticated.user - User details.
 * @param {string} session.authenticated.user.role - Role of the user.
 */function setGainsightIdentity(session){if(isInitialized){return;}if(!session){session = JSON.parse(localStorage.getItem('ember_simple_auth-session'));}loadGainsightScript().then(function(){if(session && session.authenticated && session.authenticated.isAnonymous === false){(function(){isInitialized = true;var productCode='CNLN';var customerId=session.authenticated.tenant.tenantName;var customerName=session.authenticated.tenant.tenantName;var userId=window.atob(localStorage.getItem('userEmail'));var role=session.authenticated.user.role;(0,_gooruWebUtilsHash.sha256)(userId).then(function(hashedUserId){if(window.aptrinsic){window.aptrinsic('identify',{id:productCode + '-' + customerId + '-' + hashedUserId,role:role},{id:productCode + '-' + customerId,name:customerName});}});})();}});} /**
 * Resets the Gainsight identity and removes the Gainsight script from the DOM.
 *
 * This function calls the `reset` method on the Gainsight API (if available)
 * and removes the dynamically loaded script from the document.
 */function resetGainSight(){if(window.aptrinsic && typeof window.aptrinsic === 'function'){window.aptrinsic('reset');}var scriptElement=document.querySelector('script[src="' + scriptSrc + '"]');if(scriptElement){scriptElement.remove();}}});